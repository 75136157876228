import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders, HttpErrorResponse } from '@angular/common/http';

import * as _G from '../_config/globals';
import { Observable } from 'rxjs';
import { Employee } from '../_models/employee';

@Injectable({
	providedIn: 'root'
})
export class BridgeService {

	constructor(private http: HttpClient) { }

	getAllEmployees(): Observable<Employee[]> {
		return this.http.get<Employee[]>(`${_G.phpBridge}contracts/employees`);
	}

	getEmployees(history: number = 0): Observable<Employee[]> {
		return this.http.get<Employee[]>(`${_G.phpBridge}contracts/employees?history=${history}`);
	}

	getEmployeeById(id): Observable<Employee> {
		var url = _G.phpBridge + `contracts/employees/id/${id}`;
		return this.http.get<Employee>(url);
	}

	getEmployeeForCertificate(certGuid: string): Observable<Employee> {
		return this.http.get<Employee>(`${_G.phpBridge}contracts/employees/cert/${certGuid}`);
	}

	public validatePID(personId: string) {
		var url = _G.phpBridge + `contracts/validatePID/${personId}`;
		return this.http.get(url);
	}

	public isPersonEnabled(personId: string) {
		var url = _G.phpBridge + `contracts/isPersonEnabled/${personId}`;
		return this.http.get(url);
	}

	public searchPersonsBySSN(ssn: string) {
		var url = _G.phpBridge + `contracts/searchPersonsBySSN/${ssn}`;
		return this.http.get(url);
	}

	public getPerson(personId: string) {
		var url = _G.phpBridge + `contracts/person/${personId}`;
		return this.http.get(url);
	}

	public updatePerson(personId, data: object) {
    var url = _G.phpBridge + `contracts/person/${personId}`;
		var jsonData = JSON.stringify(data);

		return this.http.post(url, jsonData);
	}

	public getContractor(personId: string) {
		var url = _G.phpBridge + `contracts/contractor/${personId}`;
		return this.http.get(url);
	}

	public preview(data: Object) {
		var url = _G.phpBridge + `contracts/preview`;
		var jsonData = JSON.stringify(data);
		
		return this.http.post(url, jsonData);
	}

	public generate(data: Object) {
		var url = _G.phpBridge + `contracts/generate`;

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			})
		};

		var jsonData = JSON.stringify(data);
		
		return this.http.post(url, jsonData);
	}

	public roadTest(data: Object) {
		var url = _G.phpBridge + `contracts/roadTest`;

		const httpOptions = {
			headers: new HttpHeaders({
				'Content-Type': 'application/json',
			})
		};

		var jsonData = JSON.stringify(data);
		
		return this.http.post(url, jsonData);
	}

	public saveContractDate(driverId: number, date: string) {
		let headers = new HttpHeaders({
			'Content-Type': 'application/json',
			'Authorization': 'Basic SVZTb2x1dGlvbjoxUzBsdXQxMG4yQDIy'
		});
		let requestOptions = { headers: headers };
		return this.http.get(`http://extend.jdmexpedite.com/api/drivers/setContractDate?driver_id=${driverId}&contract_date=${date}`, requestOptions);
	}
}
