import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { AppRoutingModule } from './app-routing.module';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';

import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MaterialModule } from './_modules/material/material.module';

import { AppComponent } from './app.component';



/**
 * ===================================================================
 * Layout
 * ===================================================================
 */

import { HeaderComponent } from './_layout/header/header.component';
import { FooterComponent } from './_layout/footer/footer.component';

import { InstructorLayoutComponent } from './_layout/instructor-layout/instructor-layout.component';
import { SafetyLayoutComponent } from './_layout/safety-layout/safety-layout.component';

// Pages
// ------------------------------------------------------------------
import { MainMenuComponent } from './_layout/main-menu/main-menu.component';
import { AuthComponent } from './_layout/auth/auth.component';


/**
 * ===================================================================
 * Services
 * ===================================================================
 */
import { AuthService } from './_services/auth/auth.service';
import { BridgeService } from './_services/bridge.service';
import { EmployeeService } from './_services/employee.service';

import { EmployeesStoreService } from './_services/store/employees-store.service';
import { AppStoreService } from './_services/store/app-store.service';

/**
 * ===================================================================
 * Helpers
 * ===================================================================
 */

// Interceptors
// ------------------------------------------------------------------
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';

// Fake backend
// ------------------------------------------------------------------
import { fakeBackendProvider } from './_helpers/fake-backend';
import { AuthStoreService } from './_services/auth/auth-store.service';

import { DatePipe } from '@angular/common';

@NgModule({
  declarations: [
	AppComponent,
	HeaderComponent,
	FooterComponent,
	MainMenuComponent,
    AuthComponent,
    InstructorLayoutComponent,
    SafetyLayoutComponent
  ],
  imports: [
	BrowserModule,
	BrowserAnimationsModule,
	AppRoutingModule, 
	HttpClientModule,
	FormsModule, ReactiveFormsModule,
	MaterialModule,
	
  ],

  providers: [
	  AppStoreService,
	  AuthService, AuthStoreService,
	  EmployeeService, EmployeesStoreService,
	  BridgeService, 
	  DatePipe,
	  { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
	  { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
	  // provider used to create fake backend
	  fakeBackendProvider
	],
  bootstrap: [AppComponent]
})
export class AppModule { }
