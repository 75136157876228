export interface AuthResponse {
	token: string;
	user?: User;
	guid?: string;
} 

export class User {

	public constructor() {}
	
    id: number;
	username: string;
	password: string;

	token?: string;

	fullName?: string;
	email?: string;
	data?: any;
	companyCode?: string;
	companyRoles?: Role;
}

export class UserLoginData {

	public constructor() {}
	
    CompanyCode: string;
    Email: string;
    Password: string;
}

export enum Role {
    Safety = 'Safety',
    Instructor = 'Instructor'
}