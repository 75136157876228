import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { Employee } from '../../_models/employee';
import { BridgeService } from "../bridge.service";

@Injectable({providedIn: 'root'})
export class EmployeesStoreService {
	private readonly _employees = new BehaviorSubject<Employee[]>(null);
	readonly employees$ = this._employees.asObservable();

	constructor(private service: BridgeService) {	
		this.fechAll();
	}

	public fechAll() {
		this.service.getAllEmployees().subscribe(
			response => this.employees = response
		);
	}

	get employees(): Employee[] { return this._employees.getValue(); }
	set employees(val: Employee[]) { this._employees.next(val); }
}