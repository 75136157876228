import { Component, OnInit } from '@angular/core';
import { AuthStoreService } from '@app/_services/auth/auth-store.service';
import { Role } from '@app/_models/user';
import { AppStoreService } from '@app/_services/store/app-store.service';

@Component({
  selector: 'app-main-menu',
  templateUrl: './main-menu.component.html',
  styleUrls: ['./main-menu.component.scss']
})
export class MainMenuComponent implements OnInit {

	isSafety = false;
	isInstructor = false;

  constructor(
	  private authStore: AuthStoreService,
	  private appStore: AppStoreService
  ) {
	this.appStore.title = "Main page"
   }

  ngOnInit() {
	  this.authStore.auth$.subscribe(
		  auth => {
			  if(auth) {
				if(auth.companyRoles == Role.Instructor) { this.isInstructor = true }
				if(auth.companyRoles == Role.Safety) { this.isSafety = true }
			  }
		  }
	  );
  }





}
