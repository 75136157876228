import { environment } from '../../environments/environment';

export const DS = '/';

export const phpBridge = environment.services.bridge;
export const employeeAPI = environment.services.employee;

export const orientationURL = environment.orientationURL;

export const LocalVariable = {
	Auth: "jdmContractAuthLocal_02.2020"
}

