import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { AuthStoreService } from './auth-store.service';

@Injectable({
	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(private router: Router, private authStore: AuthStoreService) { }

	canActivate( route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

		const auth = this.authStore.auth;

		console.log( '[AuthGuard] Auth ', auth);

		if(auth) {
			// check if route is restricted by role
			if (route.data.roles && route.data.roles.indexOf(auth.companyRoles) === -1) {
				// role not authorised so redirect to home page
				this.router.navigate(['/']);
				return false;
			}
			
			return true;
		}


		// if (localStorage.getItem(G.LocalVariable.Auth)) {
		// 	// logged in so return true
		// 	return true;
		// }

		// not logged in so redirect to login page with the return url
        this.router.navigate(['/login'], { queryParams: { returnUrl: state.url }});
        return false;
	}
}
