import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map } from 'rxjs/operators';
import * as G from '../../_config/globals';
import { UserLoginData, User } from '../../_models/user';
import { AuthStoreService } from '../auth/auth-store.service';

import { environment } from '../../../environments/environment';



@Injectable()
export class AuthService {

	constructor( 
		private http: HttpClient, 
		private store: AuthStoreService
		) { 
			
		}

  login(username: string, password: string) {
        return this.http.post<any>(`${environment.apiUrl}/users/authenticate`, { username, password })
            .pipe(map(user => {
                // login successful if there's a jwt token in the response
                if (user && user.token) {
                    // store user details and jwt token in local storage to keep user logged in between page refreshes
                    this.loginHandler(user);
                }

                return user;
            }));
    }

    logout() {
        // remove user from local storage to log user out
		localStorage.removeItem(G.LocalVariable.Auth);
		this.store.auth = null;
	}

	loginHandler(user: User) {
		localStorage.setItem(G.LocalVariable.Auth, JSON.stringify(user));
		this.store.auth = user;
	}
}
