import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { AppStoreService } from '../../_services/store/app-store.service';
import { AuthStoreService } from '@app/_services/auth/auth-store.service';
import { Role } from '@app/_models/user';

@Component({
	selector: 'app-header',
	templateUrl: './header.component.html',
	styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
	isSafety = false;
	isInstructor = false;

	constructor(private router: Router, public appStore: AppStoreService, private authStore: AuthStoreService) { }

	ngOnInit() {
		this.authStore.auth$.subscribe(
			
			auth => {
				if(auth) {
					if(auth.companyRoles == Role.Instructor) 	{ this.isInstructor = true; this.isSafety = false; }
					if(auth.companyRoles == Role.Safety) 		{ this.isSafety = true; this.isInstructor = false; }
				}
			}
		);
	}

	ngAfterViewInit() { }

	openPage(page: string) {
		this.router.navigate([`/${page}`]);
	}
}
