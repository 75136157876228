import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { MainMenuComponent } from './_layout/main-menu/main-menu.component';
import { AuthComponent } from './_layout/auth/auth.component';
import { AuthGuard } from './_services/auth/auth.guard';

import { Role } from './_models/user';
import { InstructorLayoutComponent } from './_layout/instructor-layout/instructor-layout.component';
import { SafetyLayoutComponent } from './_layout/safety-layout/safety-layout.component';

const routes: Routes = [
	{
		path: '',
		redirectTo: 'main',
		pathMatch: 'full',
	},
	{ 
		path: 'main', 
		component: MainMenuComponent,
		canActivate: [AuthGuard],
		data: { title: 'JDM SOFTWARE' }
	},
	{ 
		path: 'login', 
		component: AuthComponent,
	},
	{
        path: '',
		component: InstructorLayoutComponent,
		canActivate: [AuthGuard],
		data: { roles: [Role.Instructor] },
        children: [
            {
				path: 'instructor',
                loadChildren: './_modules/instructor/instructor.module#InstructorModule'
			},
        ]
	},
	{
        path: '',
		component: SafetyLayoutComponent,
		canActivate: [AuthGuard],
		data: { roles: [Role.Safety] },
        children: [
            {
				path: 'safety',
                loadChildren: './_modules/safety/safety.module#SafetyModule'
			},
        ]
	},		
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }