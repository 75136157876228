import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { User } from '@app/_models/user';

import * as G from '@app/_config/globals';

@Injectable({providedIn: 'root'})
export class AuthStoreService {

	private readonly _auth = new BehaviorSubject<User>(null);

	readonly auth$ = this._auth.asObservable();

	constructor() { 
		this.auth = JSON.parse(localStorage.getItem(G.LocalVariable.Auth));
	}

	get auth(): User { return this._auth.getValue(); }
	set auth(val: User) { this._auth.next(val); }
}