import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";

@Injectable({providedIn: 'root'})
export class AppStoreService {
	private readonly _title = new BehaviorSubject<string>(null);
	readonly title$ = this._title.asObservable();

	constructor() {	
		this.title = "JDM Contracts";
	}

	get title(): string { return this._title.getValue(); }
	set title(val: string) { this._title.next(val); }
}