// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	//apiUrl: 'http://contract.local/', // fake backend api url
	apiUrl: 'https://contract.jdmexpedite.com',
  orientationURL: 'https://orientation.jdmexpedite.com',


	services: {
	  //employee: { url: 'https://localhost:44320/api', token: 'a0f0d5d9-f86f-477b-8603-9b549469b1a8'},
	//  employee: { url: 'https://jdm-employeeapi-prod.azurewebsites.net/api', token: 'a0f0d5d9-f86f-477b-8603-9b549469b1a8'},
    employee: { url: 'https://emplyeesapi.azurewebsites.net/api', token: 'a0f0d5d9-f86f-477b-8603-9b549469b1a8' },
	 // bridge: 'http://localhost/api/'
	  bridge: 'https://bridge.jdmexpedite.com/api/'
	}
  };

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
 import 'zone.js/dist/zone-error';  // Included with Angular CLI.
