import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';

import { Observable } from 'rxjs';
import { Employee } from '../_models/employee';
import * as G from '../_config/globals';

@Injectable({
	providedIn: 'root'
})
export class EmployeeService {

	constructor(private http: HttpClient) { }

	getEmployeeForCertificate(certGuid: string): Observable<Employee> {
		return this.http.get<Employee>(G.employeeAPI.url + `/employees/certificate?guid=${certGuid}&api_token=${G.employeeAPI.token}`);
	}

	getEmployeeById(id: string): Observable<Employee> {
		return this.http.get<Employee>(G.employeeAPI.url + `/employees/${id}?api_token=${G.employeeAPI.token}`);
	}
}

