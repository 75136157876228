import { Component, OnInit } from '@angular/core';
import { AuthService } from '@app/_services/auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { AppStoreService } from '@app/_services/store/app-store.service';

@Component({
  selector: 'app-auth',
  templateUrl: './auth.component.html',
  styleUrls: ['./auth.component.scss']
})
export class AuthComponent implements OnInit {

  constructor( 
	  private service: AuthService, 
	  private route: ActivatedRoute,
	  private router: Router, 
	  private formBuilder: FormBuilder,
	  private appStore: AppStoreService
  ) {
	this.appStore.title = "Login page"
   }
  	loginForm: FormGroup;
	loading = false;
	submitted = false;

	returnUrl: string;

  ngOnInit() {

		this.service.logout();
	  		// Create login form
		this.loginForm = this.formBuilder.group({
            email: ['', Validators.required],  // TODO: define env variables for this
            password: ['', Validators.required]
		});
		
		// get return url from route parameters or default to admin panel
		this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || `${"/"}`;
  }

  
  // convenience getter for easy access to form fields
  get fc() { return this.loginForm.controls; }

  onSubmit() {

		this.submitted = true;
		this.loading = true;

		// stop here if form is invalid
		if (this.loginForm.invalid) { return }

		this.service.login(this.fc.email.value, this.fc.password.value).subscribe(
			response => {
				if(response) {
					console.log('[LoginComponent] Success: ', response);
						this.router.navigate([this.returnUrl]);
				} else {
					alert('Login failed wrong user credentials!');
					this.loading = false;
				}
			}, error => {
				console.error('[LoginComponent] error: ', error);
				alert(error);
				this.loading = false;
			}
		);
  }
}
